import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { IconClose } from '../components/Icons';
import { above } from '../styles/MediaQueries';

const circleAnimation = keyframes`
  0% { clip-path: circle(75%); opacity: 1;}
  100% { clip-path: circle(0%); opacity: 0; }
`;

const StyledModalOuter = styled.div`
  display: grid;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  opacity: 0;
  pointer-events: none;
  transition: all 0.1s ease;
  z-index: 2;
  overflow-y: auto;
  overflow-x: hidden;

  &.is-open {
    opacity: 1;
    /* animation: 0.5s ${circleAnimation} cubic-bezier(0.76, -0.245, 0.24, 1.245)
      reverse;
    animation-fill-mode: forwards; */
    pointer-events: all;
    > div,
    > div:before,
    > div:after {
      transform: translateY(0);
      opacity: 1;
    }
  }
`;

const StyledModalInner = styled.div`
  /* width: 100vw;
  height: 100vh; */
  max-width: 600px;
  min-height: 200px;
  background-color: white;
  padding: 2rem;
  margin: 0 1rem;
  ${above.medium`
		padding: 3rem;
		margin: 0;
	`}
  /* box-shadow: 10px 10px 10px rgba(0,0,0,.14); */
  overflow-y: auto;
  position: relative;
  border-radius: 0;
  ${above.medium`
		border-radius: 5px;
	`}
  transition: all 0.1s ease;
  transform: translateY(-40px);

  ul,
  h4 {
    text-align: left;
  }

  ul {
    padding-left: 1.25rem;
  }

  h4 {
    margin-top: 2rem;
  }

  .close-button {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 1em;
    &:focus {
      outline: none;
    }
    &:hover {
      opacity: 0.66;
    }
  }
`;

const StyledCircleEffect = styled.div`
  position: relative;
  z-index: 0;
  transition: all 0.3s cubic-bezier(0.76, -0.245, 0.24, 1.245);
  /* transform: translateY(-120px); */
  /* &:before {
    transition: all 0.5s cubic-bezier(0.76, -0.245, 0.24, 1.245);
    transition-delay: 0.4s;
    opacity: 0;
    transform: scale(0.5);
    position: absolute;
    top: -25%;
    left: -50%;
    width: 660px;
    height: 660px;
    background-color: var(--moonlight-10);
    border-radius: 50%;
    content: '';
    z-index: -1;
  } */
`;

// https://www.nicknish.co/blog/react-modal-with-usestate-and-portals

export default function MiniModal({ children, activator }) {
  const [isMiniModalOpen, setIsMiniModalOpen] = useState(false);
  const ref = useRef();
  useOnClickOutside(ref, () => setIsMiniModalOpen(false));

  function useOnClickOutside(ref, handler) {
    useEffect(() => {
      const listener = event => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      };

      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);

      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    }, [ref, handler]);
  }

  // close on esc
  useEffect(() => {
    function handleKeyDown(event) {
      if (event.key === 'Escape' && isMiniModalOpen) {
        setIsMiniModalOpen(false);
      }
    }

    window.addEventListener('keydown', handleKeyDown);
    // https://www.pluralsight.com/guides/how-to-cleanup-event-listeners-react
    return function cleanUpListener() {
      window.removeEventListener('keydown', handleKeyDown);
    };
  });

  // https://usehooks.com/useLockBodyScroll/
  // TODO: Improve so eefect only run at mount and unmount
  // useLayoutEffect vs useEffect
  function useLockBodyScroll() {
    useLayoutEffect(() => {
      if (isMiniModalOpen) {
        document.body.style.overflow = 'hidden';
      }
      return () => (document.body.style.overflow = 'auto');
    });
  }

  useLockBodyScroll();

  const modalContent = (
    <StyledModalOuter className={isMiniModalOpen ? 'is-open' : 'is-closed'}>
      <StyledModalInner ref={ref}>
        <button
          className="close-button"
          onClick={() => setIsMiniModalOpen(false)}
        >
          <IconClose size="1.5" />
        </button>
        {children}
        {/* <a
            className="inline-link mt-2"
            onClick={() => setIsMiniModalOpen(false)}
          >
            Schließen
          </a> */}
      </StyledModalInner>
    </StyledModalOuter>
  );
  return (
    <>
      {activator({ setIsMiniModalOpen })}
      {/* {createPortal(modalContent, document.body)} */}
      {modalContent}
    </>
  );
}
