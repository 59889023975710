import React, { useState } from 'react';
import styled from 'styled-components';
import { above, below } from '../styles/MediaQueries';
import MiniModal from './MiniModal';

const StyledMoonPhases = styled.div`
  h4 {
    text-transform: none;
    font-size: var(--font-l);
    font-weight: 600;
    letter-spacing: normal;
    ${above.small`
    	// font-size: var(--font-l);
		`}
  }

  ul {
    /* text-align: left; */
  }
`;

const StyledCategoryHeadline = styled.h3`
  color: ${props => (props.color ? props.color : 'var(--brand)')};
  color: var(--text);
  text-align: center;
  flex: 1 0 100%;
  font-size: var(--font-base);
  font-weight: 600;
  text-transform: uppercase;
  width: 100%;
  ${below.medium`
		margin-top: 0;
	`}
`;

const StyledCircleGrid = styled.div`
  display: grid;
  /* grid-template-columns: repeat(auto-fit, minmax(160px, 1fr)); */
  grid-template-columns: repeat(1, minmax(176px, 1fr));
  ${above.medium`
  grid-template-areas:
    'first second third'
		'fourth fourth fourth';
		`}
  ${above.tiny`
  	grid-template-columns: repeat(${props =>
      props.row ? '2' : '1'}, minmax(0, 1fr));
	`}
  ${above.medium`
  	grid-template-columns: repeat(3, minmax(242px, 1fr));
	`}
  grid-gap: 1rem;
  ${above.small`
		grid-gap: 2rem;
	`}

  /* align-items: start; */

  /* justify-content: space-between; */

  > * {
    display: flex;
    justify-content: space-around;
    text-align: center;
    flex-wrap: wrap;
    // last row with 3 elements
    // fixes alignment issues
    &:nth-of-type(4) {
      padding-left: 0.25rem;
      padding-right: 0.25rem;
      h3 {
        margin-top: 0;
      }
      ${above.medium`
				grid-area: fourth;
				justify-content: space-between;
				> * {
					margin-left: .5rem;
					margin-right: .5rem;
				}

				}
			`}
    }
  }
`;

const StyledCircle = styled.div`
  background-color: ${props => (props.bg ? props.bg : 'blue')};
  color: ${props => (props.color ? props.color : 'var(--text)')};
  height: 160px;
  width: 160px;
  position: relative;
  cursor: pointer;
  z-index: 0;
  ${above.tiny`
  	height: 210px;
		width: 210px;
	`}
  ${above.small`
  	height: 240px;
		width: 240px;
	`}
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  &:not(:last-child) {
    margin-bottom: 2rem;
  }
  > * {
    margin: 0;
  }

  transition: all 0.2s ease;

  &:hover {
    /* transform: scale(1.04); */
    opacity: 0.8;
  }
`;

function SingleCircle({ content, onClick }) {
  const fontColor = content.fontColor === 'bright' ? '#fff' : '#000';
  const backgroundColor = content.backgroundColor;

  return (
    <StyledCircle
      style={{ backgroundColor: `${backgroundColor}` }}
      onClick={onClick}
    >
      <h4 style={{ color: `${fontColor}` }}>
        {content.headline.map((headlineLine, i) => (
          <span key={i}>
            {headlineLine}
            {i < content.modalHeadline.length - 1 ? <br /> : ''}
          </span>
        ))}
      </h4>
    </StyledCircle>
  );
}

export default function MoonPhasesDynamic({ circles }) {
  return (
    <StyledMoonPhases>
      <h2>Angebote in allen MOND-Phasen</h2>
      <p>
        Klicken Sie auf ein konkretes Angebot, um die Beispiele zu erfahren.
      </p>
      <StyledCircleGrid className="mt-2">
        {circles.map(circleChapter => (
          <div key={circleChapter.headline}>
            <StyledCategoryHeadline>
              {circleChapter.headline}
            </StyledCategoryHeadline>
            {circleChapter.circleReference.map(c => (
              <MiniModal
                key={c.backgroundColor}
                activator={({ setIsMiniModalOpen }) => (
                  <SingleCircle
                    onClick={() => setIsMiniModalOpen(true)}
                    content={c}
                  ></SingleCircle>
                )}
              >
                {/* Modal Content */}
                <h4>{c.modalHeadline}</h4>
                <ul>
                  {c.modalBullets.map((bullet, i) => (
                    <li key={i}>{bullet}</li>
                  ))}
                </ul>
              </MiniModal>
            ))}
          </div>
        ))}
      </StyledCircleGrid>
    </StyledMoonPhases>
  );
}
