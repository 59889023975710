import React from 'react';
import { graphql } from 'gatsby';
import Hero from '../components/Hero';
import IntroSection from '../components/IntroSection';
import GridColumn from '../layout/Grid/GridColumn';
import GridContainer from '../layout/Grid/GridContainer';
import GridRow from '../layout/Grid/GridRow';
import Section from '../layout/Section';
import IconList from '../components/IconList.js';
import SEO from '../components/SEO';
import MoonPhases from '../components/MoonPhases';
import MoonPhasesDynamic from '../components/MoonPhasesDynamic';
import Button from '../components/Button';
import Testimonial from '../components/Testimonial';

export default function AngebotPage({ data }) {
  return (
    <>
      <SEO title={data.page.seoTitle} description={data.page.seoDescription} />

      <Hero hero={data.page} />
      <Section>
        <GridContainer>
          <GridRow>
            <GridColumn start="3" span="8">
              <IntroSection intro={data.page} />
            </GridColumn>
          </GridRow>
        </GridContainer>
      </Section>

      <Section color="subtle">
        <GridContainer>
          <GridRow>
            <GridColumn start="3" span="9">
              <MoonPhasesDynamic circles={data.circles.circleBuilder} />
            </GridColumn>
          </GridRow>
        </GridContainer>
      </Section>

      <Section>
        <GridContainer>
          <GridRow>
            <GridColumn start="3" span="8">
              <h2>Raum-Fahrten</h2>
              <p>
                Ganzheitliche, nachhaltige und nutzerzentrierte
                Mobilitätsdesigns sind immer so individuell wie die Stadt, das
                Quartier oder die betrieblichen Verkehre. Ob Pendler-Mobilität,
                City-Logistik, Gesundheitsverkehre, Mieter-Mobilität oder
                Kunden- und Besucher-Verkehre: Gemeinsam mit unseren Kunden
                bringen wir die Projekte auf die Straße.
              </p>
              <p>
                Wir sind in verschiedenen Nutzungsbereichen von Quartieren und
                Unternehmen unterwegs und haben in den vergangenen Jahren für
                viele Branchen spezifische Lösungen erarbeitet:
              </p>
              <IconList />
            </GridColumn>
          </GridRow>
        </GridContainer>
      </Section>

      <Section className="pt-0">
        <GridContainer>
          <GridRow>
            <GridColumn start="3" span="8">
              <Testimonial source={data.testimonial} />
            </GridColumn>
          </GridRow>
        </GridContainer>
      </Section>

      <Section className="pt-0">
        <GridContainer>
          <GridRow>
            <GridColumn start="3" span="8">
              <h2>Flug-Begleiter</h2>
              <p>
                <strong>
                  MOND ist Ihr Flugbegleiter für Ihre Mobilitäts-Mission. Wir
                  arbeiten unter anderem mit:
                </strong>
              </p>
              <ul>
                <li>Top-Management bei Strategie-Projekten</li>
                <li>Quartiersentwickler & Immobilienentwickler</li>
                <li>Architekten</li>
                <li>Verkehrs- und Baudezernate</li>
                <li>Fuhrpark-& Gebäude-Management</li>
                <li>
                  Personalmanagement & betriebliches Gesundheitsmanagement{' '}
                </li>
                <li>Einkauf & Beschaffung </li>
                <li>Marketing & Kommunikation</li>
                <li>Nachhaltigkeitsmanagement</li>
              </ul>
              <p>
                <strong>und am liebsten mit allen zusammen.</strong> Denn
                Mobilitätskonzepte brauchen kluge Kreuzungen und Brücken.
              </p>

              <h3 className="mt-4">Sie möchten uns näher kennenlernen?</h3>
              <p>Wir freuen uns über Ihre Anfrage.</p>
              <Button to="/kontakt/" className="secondary">
                Kontakt
              </Button>
            </GridColumn>
          </GridRow>
        </GridContainer>
      </Section>
    </>
  );
}

export const query = graphql`
  query AngebotQuery {
    page: sanityMainPages(slug: { current: { eq: "angebot" } }) {
      headline
      seoTitle
      seoDescription
      _rawIntroCopy
      heroHeadline
      enableHero
      heroImage {
        alt
        asset {
          fluid(maxWidth: 1920) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }

    testimonial: sanityTestimonial(
      _id: { eq: "a541b37d-4a6d-406d-b945-9c4dbeafde43" }
    ) {
      name
      function
      quote
    }

    circles: sanityMoonPhaseCircleBuilder(
      _id: { eq: "moonPhaseCircleBuilder" }
    ) {
      circleBuilder {
        headline
        row
        circleReference {
          headline
          fontColor
          backgroundColor
          modalHeadline
          modalBullets
        }
      }
    }
  }
`;
