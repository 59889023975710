import React from 'react';
// import { above, below } from "../../styles/M^ediaQueries";
import styled from 'styled-components';
import {
  IconAirport,
  IconArch,
  IconBike,
  IconBoss,
  IconBuilding,
  IconCity,
  IconCowork,
  IconCulture,
  IconFranchise,
  IconGastro,
  IconHealth,
  IconHotel,
  IconImmo,
  IconShop,
} from '../components/Icons';
import EqGrid from '../layout/Grid/EqGrid.js';

const StyledIconList = styled.div`
  margin-bottom: 0;

  ul {
    padding: 0;
    margin: 0;
  }

  li {
    font-family: var(--sans-serif);
    list-style-type: none;
    font-weight: 600;
    line-height: 1.2;
    text-transform: uppercase;

    > * {
      display: inline-flex;
      align-items: center;
    }

    svg {
      margin-right: 1em;
    }
  }
`;

export default function IconList() {
  return (
    <EqGrid md="2" className="mt-4">
      <StyledIconList>
        <ul>
          <li>
            <div>
              <IconBoss size="3" />
              Arbeitgeber
            </div>
          </li>
          <li>
            <div>
              <IconImmo size="3" />
              Immobilienentwickler
            </div>
          </li>
          <li>
            <div>
              <IconCowork size="3" />
              Co-Working Space-Anbieter
            </div>
          </li>
          <li>
            <div>
              <IconAirport size="3" />
              Flughäfen
            </div>
          </li>
          <li>
            <div>
              <IconHotel size="3" />
              Hotelerie
            </div>
          </li>
          <li>
            <div>
              <IconFranchise size="3" />
              Filial- und Franchiseunternehmen
            </div>
          </li>
          <li>
            <div>
              <IconShop size="3" />
              Handel
            </div>
          </li>
        </ul>
      </StyledIconList>

      <StyledIconList>
        <ul>
          <li>
            <div>
              <IconCity size="3" />
              Kommunen & Städte
            </div>
          </li>
          <li>
            <div>
              <IconBuilding size="3" />
              Wohnungsbaugesellschaften
            </div>
          </li>
          <li>
            <div>
              <IconArch size="3" />
              Architektenbüros
            </div>
          </li>
          <li>
            <div>
              <IconBike size="3" />
              Logistiker
            </div>
          </li>
          <li>
            <div>
              <IconGastro size="3" />
              Gastronomie
            </div>
          </li>
          <li>
            <div>
              <IconHealth size="3" />
              Gesundheitsunternehmen
            </div>
          </li>
          <li>
            <div>
              <IconCulture size="3" />
              Kultureinrichtungen
            </div>
          </li>
        </ul>
      </StyledIconList>
    </EqGrid>
  );
}
