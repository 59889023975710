import React, { useState } from 'react';
import styled from 'styled-components';
import { above, below } from '../styles/MediaQueries';

const StyledMoonPhases = styled.div`
  h4 {
    text-transform: none;
    font-size: var(--font-l);
    font-weight: 600;
    letter-spacing: normal;
    ${above.small`
    	// font-size: var(--font-l);
		`}
  }
`;

const StyledCategoryHeadline = styled.h3`
  color: ${props => (props.color ? props.color : 'var(--brand)')};
  text-align: center;
  flex: 1 0 100%;
  font-size: var(--font-base);
  font-weight: 600;
  text-transform: uppercase;
  width: 100%;
  ${below.medium`
		margin-top: 0;
	`}
`;

const StyledCircleGrid = styled.div`
  display: grid;
  /* grid-template-columns: repeat(auto-fit, minmax(160px, 1fr)); */
  grid-template-columns: repeat(1, minmax(176px, 1fr));
  ${above.tiny`
  	grid-template-columns: repeat(${props =>
      props.row ? '2' : '1'}, minmax(0, 1fr));
	`}
  ${above.medium`
  	grid-template-columns: repeat(3, minmax(242px, 1fr));
	`}
  grid-gap: 1rem;
  ${above.small`
		grid-gap: 2rem;
	`}

  > * {
    display: flex;
    justify-content: space-around;
    text-align: center;
    flex-wrap: wrap;
  }
`;

const StyledCircle = styled.div`
  background-color: ${props => (props.bg ? props.bg : 'blue')};
  color: ${props => (props.color ? props.color : 'var(--text)')};
  height: 160px;
  width: 160px;
  position: relative;
  cursor: pointer;
  z-index: 0;
  &:hover {
    opacity: 0.8;
  }
  ${above.tiny`
  	height: 210px;
		width: 210px;
	`}
  ${above.small`
  	height: 240px;
		width: 240px;
	`}
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  &:not(:last-child) {
    margin-bottom: 2rem;
  }
  > * {
    margin: 0;
  }
`;

function SingleCircle({ bg, color, children }) {
  return (
    <StyledCircle bg={bg} color={color} children={children}>
      <h3>{children}</h3>
    </StyledCircle>
  );
}

export default function MoonPhases({ bg, color }) {
  return (
    <StyledMoonPhases>
      <h2>Angebote in allen MOND-Phasen</h2>
      <StyledCircleGrid>
        <div>
          <StyledCategoryHeadline color="#a49f92">
            Analyse
          </StyledCategoryHeadline>
          <SingleCircle bg="#d7d1c5" color={color}>
            <h4>
              Mobilitäts-
              <br />
              Bedarfs-
              <br />
              analysen
            </h4>

            {/* <h4>Datenbasierte Analysen</h4>
              <ul>
                <li>Pendler-, Mieter-, Kunden-Logistik-Verkehre</li>
                <li>Quartiers- & Unternehmens-Verkehre</li>
                <li>Ist- & Soll-Verkehre im Modal Split</li>
              </ul> */}
          </SingleCircle>
          <SingleCircle bg="#e6e3dc" color={color}>
            <h4>
              Infrastruktur-
              <br />
              Bedarfs-
              <br />
              analysen
            </h4>
          </SingleCircle>
        </div>

        <div>
          <StyledCategoryHeadline>Konzeption</StyledCategoryHeadline>
          <SingleCircle bg="#3256a4" color="white">
            <h4>
              Mobilitäts-
              <br />
              Strategie
            </h4>
          </SingleCircle>
          <SingleCircle bg="#7187c0" color="white">
            <h4>
              Mobilitäts-
              <br />
              Konzept &
              <br />
              Architektur
            </h4>
          </SingleCircle>
        </div>

        <div>
          <StyledCategoryHeadline color="#98aad2">
            Umsetzung
          </StyledCategoryHeadline>
          <SingleCircle bg="#98aad2">
            <h4>
              Mobilitäts-
              <br />
              Konzept-
              <br />
              Umsetzung
            </h4>
          </SingleCircle>
          <SingleCircle bg="#c3d5ed">
            <h4>
              Rad-/Mikro-
              <br />
              Mobilität &
              <br />
              Wartung
            </h4>
          </SingleCircle>
        </div>
      </StyledCircleGrid>

      <StyledCategoryHeadline color="#ddcb77">
        Kommunikation
      </StyledCategoryHeadline>
      <StyledCircleGrid row="true">
        <div>
          <SingleCircle bg="#ddcb77">
            <h4>
              Vorträge &
              <br />
              Impulse
            </h4>
          </SingleCircle>
        </div>
        <div>
          <SingleCircle bg="#e8dda5">
            <h4>
              Interne &
              <br />
              externe
              <br />
              Workshops
            </h4>
          </SingleCircle>
        </div>
        <div>
          <SingleCircle bg="#f0eac8">
            <h4>
              Kommuni-
              <br />
              kation &
              <br />
              Marketing
            </h4>
          </SingleCircle>
        </div>
      </StyledCircleGrid>
    </StyledMoonPhases>
  );
}
